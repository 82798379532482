// action strings
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export interface FetchErrorAction {
  type: typeof FETCH_ERROR;
  error: string;
}

export interface ShowMessageAction {
  type: typeof SHOW_MESSAGE;
  message: string;
}

export interface HideMessageAction {
  type: typeof HIDE_MESSAGE;
}

export type CommonActions =
  | FetchErrorAction
  | ShowMessageAction
  | HideMessageAction;
