import React from 'react';
import { Box, Typography } from '@mui/material';
import { AppColor } from '../types/Constants';

const NotFoundPage = () => {

    return (
        <Box
            sx={{
                height: "100vh",
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                background: AppColor.skidattlDark
            }}>
            <Box>
                <img src="/skidattl-logo-white.png" width={200} alt="Skidattl Logo" />
            </Box>
            <Box sx={{ mb: 5 }}>
                <Box
                    component="h3"
                    sx={{
                        mb: 3,
                        fontSize: 84,
                        color: "white",
                        fontWeight: 600,
                    }}>
                    404
                </Box>
                <Box
                    sx={{ mb: 8 }}>
                    <Typography sx={{
                        color: "gray",
                        fontSize: 24,
                        fontWeight: 600,
                    }}>Page Not Found</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default NotFoundPage;
