import {
  CommonActions,
  FETCH_ERROR,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
} from "../../types/actions/Common.action";

export const fetchError = (error: string): CommonActions => ({
  type: FETCH_ERROR,
  error,
});

export const showMessage = (message: string): CommonActions => ({
  type: SHOW_MESSAGE,
  message,
});

export const hideMessage = (): CommonActions => ({ type: HIDE_MESSAGE });
