import { BeaconCategories } from "../types/Constants";
import { getApiRoot } from "./beaconAxios";

export const getDisplayDate = (date: Date) => {
    return date.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" });
};

export const getDisplayTime = (date: Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutesStr + ' ' + ampm;

    return strTime;
};

export const getCategoryName = (key: string) => {
    if (BeaconCategories.has(key)) {
        return BeaconCategories.get(key);
    }
    else {
        return "-";
    }
};

export const getCategoryColor = (key: string) => {

    switch (key) {
        case "fooddrink":
            return "#CC3333"; // Persian Red
        case "service":
            return "#FF6633"; // Smashed Pumpkin
        case "retail":
            return "#3366CC"; // Celtic Blue
        case "entertainment":
            return "#3399CC"; // Moonstone
        case "recreation":
            return "#663399"; // Rebecca Purple
        case "community":
            return "#006633"; // Cadmium Green
        case "personal":
            return "#FFCC33"; // Sunglow
        default:
            return "#999999"; // Spanish Gray
    }
};

export const getCategoryPngSource = (key: string) => {

    switch (key) {
        case "fooddrink":
            return "/images/food.png";
        case "service":
            return "/images/service.png";
        case "retail":
            return "/images/retail.png";
        case "entertainment":
            return "/images/entertainment.png";
        case "recreation":
            return "/images/recreation.png";
        case "community":
            return "/images/community.png";
        case "personal":
            return "/images/personal.png";
        case "other":
            return "/images/other.png";
        default:
            return undefined;
    }
};

export const getBeaconImageURLOnServer = (imageId: string) => {
    const baseURL = getApiRoot();
    return baseURL + "/beacon/" + imageId + ".jpg";
};

export const getUserImageURLOnServer = (imageId: string) => {
    const baseURL = getApiRoot();
    return baseURL + "/profile/" + imageId + ".jpg";
};
