import React from 'react';
import { Provider } from "react-redux";
import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { store } from "./redux/store";
import './App.css';
import NotFoundPage from './pages/NotFoundPage';
import BeaconPage from './pages/BeaconPage';
import UserProfilePage from './pages/UserProfilePage';
import InvitePage from './pages/InvitePage';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <CssBaseline />
        <Routes>
          <Route path="/beacon/:beacon_id" element={<BeaconPage />} />
          <Route path="/friend/:friend_id" element={<UserProfilePage />} />
          <Route path="/invite/:invite_id" element={<InvitePage />} />
          <Route path="/notfound" element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
