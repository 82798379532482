import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardMedia, Skeleton, Typography } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import Div100vh from 'react-div-100vh';
import { AppColor, Fonts } from '../types/Constants';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { getDisplayDate, getUserImageURLOnServer } from '../utils/utils';
import { getUserProfile } from '../redux/actions/Preview';

const UerProfilePage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let { friend_id } = useParams();
    const previewState = useAppSelector(state => state.preview);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (friend_id) {
            setIsLoading(true);
            dispatch(getUserProfile(friend_id, (result) => {
                setIsLoading(false);
                if (!result) {
                    navigate({ pathname: "/notfound" });
                }
            }));
        }
        else {
            navigate({ pathname: "/notfound" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [friend_id]);

    const userProfileImage = () => {
        if (isLoading) {
            return <Skeleton variant="circular" width="100%" height="100%" />;
        } else {
            if (previewState.userProfile?.image_id) {
                return (
                    <CardMedia
                        sx={{ borderRadius: "50%" }}
                        component="img"
                        src={getUserImageURLOnServer(previewState.userProfile?.image_id)}
                        alt="user profile"
                    />
                );
            }
            else {
                // default image

                return (
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        backgroundColor: AppColor.basePurple,
                    }}>
                        <PersonOutlineOutlinedIcon sx={{ color: "white", fontSize: 64, fontWeight: Fonts.LIGHT }} />
                    </Box>
                );
            }
        }
    };

    const userNameElement = () => {
        if (isLoading) {
            return <Skeleton variant="text" width={140} height={32}></Skeleton>;
        } else {
            return (
                <Typography
                    sx={{
                        fontSize: 24,
                        fontWeight: Fonts.SEMI_BOLD,
                        wordBreak: "break-all",
                        color: AppColor.headerText,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical'
                    }}>
                    {previewState.userProfile?.name}
                </Typography>

            );
        }
    };

    const userDetailElements = () => {
        if (isLoading) {
            return (
                <Box >
                    <Skeleton variant='text' width={200} height={30} />
                    <Skeleton variant='text' width={160} height={30} />
                </Box>
            );
        } else {
            return (
                <Box sx={{ mx: 2 }}>
                    {previewState.userProfile?.joined && (
                        <Card sx={{ width: "100%", px: 3, py: 2, mb: 2 }}>
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <CalendarTodayIcon sx={{ fontSize: 32, color: AppColor.basePurple }} />
                                <Box sx={{ ml: 2 }}>
                                    <Typography sx={{ ml: 1, fontSize: 16 }}>
                                        Joined Date
                                    </Typography>
                                    <Typography sx={{ ml: 1, fontSize: 18, color: AppColor.subHeaderText }}>
                                        {getDisplayDate(new Date(previewState.userProfile?.joined))}
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    )}
                    <Card sx={{ px: 3, py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <MilitaryTechOutlinedIcon sx={{ fontSize: 32, color: AppColor.basePurple }} />
                            <Box sx={{ ml: 2 }}>
                                <Typography sx={{ ml: 1, fontSize: 16 }}>
                                    Badge
                                </Typography>
                                <Typography sx={{ ml: 1, fontSize: 18, color: AppColor.subHeaderText }}>
                                    {previewState.userProfile?.badges.join(", ")}
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box >
            );
        }
    };

    return (
        <Div100vh>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column", height: "100%", width: "100%", backgroundColor: AppColor.skidattlWhite }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", mt: 2 }}>
                    <img src="/skidattl-logo-black.png" width={160} alt="Skidattl Logo" />
                    <Box sx={{
                        display: "flex",
                        mt: 4,
                        width: "40vw",
                        height: "40vw",
                        maxWidth: "200px",
                        maxHeight: "200px",
                        borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white"
                    }}>
                        {userProfileImage()}
                    </Box>
                    <Box sx={{ ml: 2, mt: 1 }}>
                        {userNameElement()}
                    </Box>
                    <Box sx={{ mt: 3, width: "100%", maxWidth: "400px", mx: 2 }}>
                        {userDetailElements()}
                    </Box>
                </Box>
                <Box sx={{ marginX: 4, mb: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <img src="/skidattl-download-on-the-app-store.png" width={200} alt="App store Logo" />
                </Box>
            </Box>
        </Div100vh>
    );
};

export default UerProfilePage;
