import { alpha } from "@mui/material";

export const AppColor = {
    basePurple: "#993399",
    purpleBackground: alpha("#993399", 0.1),
    purpleBoldBackground: alpha("#993399", 0.4),
    skeletonBase: "#e3e3e3",
    skeletonHighlight: "#b8b8b8",
    subHeaderText: "#808080",
    headerText: "#333333",
    componentBackground: "#F6F6F6",
    greyUI: "#a0a0a0",
    skidattlMagenta: "#993399",
    skidattlDark: "#13141D",
    skidattlWhite: "#F6F6F6",
};

export enum Fonts {
    LIGHT = '300',
    REGULAR = '400',
    MEDIUM = '500',
    SEMI_BOLD = '600',
    BOLD = '700',
}

export const BeaconCategories: Map<string, string> = new Map<string, string>([
    ["fooddrink", "Food & Drink"],
    ["service", "Service"],
    ["retail", "Retail"],
    ["entertainment", "Entertainment"],
    ["recreation", "Recreation"],
    ["community", "Community"],
    ["personal", "Personal"],
    ["other", "Other"]
]);

export const BusinessBeaconCategories: Map<string, string> = new Map<string, string>([
    ["fooddrink", "Food & Drink"],
    ["service", "Service"],
    ["retail", "Retail"],
    ["entertainment", "Entertainment"],
    ["recreation", "Recreation"],
    ["community", "Community"]
]);
