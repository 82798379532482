import React from 'react';
import { Box, Typography } from '@mui/material';
import { AppColor } from '../types/Constants';

const InvitePage = () => {

    return (
        <Box
            sx={{
                height: "100vh",
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                background: AppColor.skidattlDark
            }}>
            <Box>
                <img src="/skidattl-logo-white.png" width={200} alt="Skidattl Logo" />
            </Box>
            <Box sx={{ mb: 5, mx: 2 }}>
                <Box
                    component="h3"
                    sx={{
                        mb: 3,
                        fontSize: 34,
                        color: "white",
                        fontWeight: 600,
                    }}>
                    Skidattl Friend Invite
                </Box>
                <Box
                    sx={{ mb: 8, mx: 1 }}>
                    <Typography sx={{
                        color: "gray",
                        fontSize: 24,
                        fontWeight: 600,
                        textAlign: 'start'
                    }}>Open Skidattl on iOS to accept the friend request.</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default InvitePage;
