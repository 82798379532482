import axios, { InternalAxiosRequestConfig } from "axios";

export function getApiRoot() {
  // Always do this for now
  return "https://testing.skidattl.com";
  
  // Get current host
  const host = window.location.host;

  // Return a different API base depending on the current host.
  if (host.includes("preview.skidattl.com")) return "https://testing.skidattl.com";
  if (host.includes("beacons.skidattl.com")) return "https://beacon-blender.skidattl.com";
  if (host.includes("localhost")) return process.env.REACT_APP_API_BASE_URL ?? '';

  // Failsafe
  return '';
}

const beaconAxios = axios.create({
  baseURL: getApiRoot(),
  withCredentials: false,
});

beaconAxios.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    if (config.headers) {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  error => {
    Promise.reject(error);
  });

beaconAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  }
);

export default beaconAxios;
