import { Beacon } from "../models/Beacon";
import { UserProfile } from "../models/UserProfile";

export const GET_BEACON_DETAIL = "GET_BEACON_DETAIL";
export const GET_USER_PROFILE = "GET_USER_PROFILE";

export interface GetBeaconDetail {
  type: typeof GET_BEACON_DETAIL;
  payload: Beacon | undefined;
}

export interface GetUserProfile {
  type: typeof GET_USER_PROFILE;
  payload: UserProfile | undefined;
}

export type PreviewActions =
  | GetBeaconDetail
  | GetUserProfile;
