import React from 'react';
import { Box } from '@mui/material';
import { getCategoryColor, getCategoryName } from '../utils/utils';
import { AppColor, Fonts } from '../types/Constants';

type Props = {
    category?: string;
    fontSize?: string
};

const BeaconCategory = ({ category, fontSize }: Props) => {

    return (<Box
        sx={{
            color: "white",
            backgroundColor: category ? getCategoryColor(category) : AppColor.componentBackground,
            border: category ? "none" : "1px solid silver",
            padding: '0px 8px',
            borderRadius: '5px',
            minWidth: "100px",
            display: 'flex',
            justifyContent: "center",
            whiteSpace: 'nowrap',
            fontSize: fontSize ? fontSize : "14px",
            fontWeight: Fonts.MEDIUM
        }}>
        {getCategoryName(category ?? "")}
    </Box>
    );
};

export default BeaconCategory;
