import { CommonState } from "../../types/models/CommonState";
import {
  CommonActions,
  FETCH_ERROR,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
} from "../../types/actions/Common.action";

const INIT_STATE: CommonState = {
  error: "",
  message: ""
};

const CommonReducer = (
  state = INIT_STATE,
  action: CommonActions
): CommonState => {
  switch (action.type) {
    case SHOW_MESSAGE: {
      return {
        error: "",
        message: action.message
      };
    }
    case FETCH_ERROR: {
      return {
        error: action.error,
        message: "",
      };
    }
    case HIDE_MESSAGE: {
      return {
        error: "",
        message: "",
      };
    }
    default:
      return state;
  }
};
export default CommonReducer;
