import { Dispatch } from "react";
import { AppActions } from "../../types/AppActions";
import { GET_BEACON_DETAIL, GET_USER_PROFILE } from "../../types/actions/Preview";
import { fetchError } from "./Common";
import beaconAxios from "../../utils/beaconAxios";

export const getBeaconDetail = (beaconId: string, callBack: (result: boolean) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    beaconAxios.get('/api/beacon/beacondetails', { params: { id: beaconId } })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_BEACON_DETAIL, payload: data.data });
          callBack(true);
        } else {
          dispatch(fetchError("Request failed. Status:" + data.status));
          callBack(false);
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        callBack(false);
      });
  };
};

export const getUserProfile = (userId: string, callBack: (result: boolean) => void) => {

  return (dispatch: Dispatch<AppActions>) => {
    beaconAxios.get('/api/profile/publicprofileforuser', { params: { friendId: userId } })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_USER_PROFILE, payload: data.data });
          callBack(true);
        } else {
          dispatch(fetchError("Request failed. Status:" + data.status));
          callBack(false);
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        callBack(false);
      });
  };
};

