import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import reducers from "../reducers";

const reducer = combineReducers({
  ...reducers,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export type AppState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch
