/* eslint-disable no-case-declarations */
import {
  PreviewActions,
  GET_BEACON_DETAIL,
  GET_USER_PROFILE
} from "../../types/actions/Preview";
import { PreviewState } from "../../types/models/PreviewState";

const initialSettings: PreviewState = {
   beacon: undefined,
   userProfile: undefined
};

const PreviewReducer = (state = initialSettings, action: PreviewActions) => {

  switch (action.type) {
    case GET_BEACON_DETAIL:
      return {
        ...state,
        beacon: action.payload
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload
      };
    default:
      return state;
  }
};

export default PreviewReducer;
