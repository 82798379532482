import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CardMedia, IconButton, Skeleton, Typography } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import Div100vh from 'react-div-100vh'
import { AppColor, Fonts } from '../types/Constants';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { getBeaconImageURLOnServer, getUserImageURLOnServer } from '../utils/utils';
import { getBeaconDetail } from '../redux/actions';
import BeaconCategory from '../components/BeaconCategory';

const BeaconPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let { beacon_id } = useParams();
    const beaconState = useAppSelector(state => state.preview);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (beacon_id) {
            setIsLoading(true);
            dispatch(getBeaconDetail(beacon_id, (result) => {
                setIsLoading(false);
                if (!result) {
                    navigate({ pathname: "/notfound" });
                }
            }));
        }
        else {
            navigate({ pathname: "/notfound" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beacon_id]);

    const userProfileImage = () => {

        if (beaconState.beacon?.owner_image_id) {
            return (
                <CardMedia
                    sx={{ width: "36px", borderRadius: "50%" }}
                    component="img"
                    src={getUserImageURLOnServer(beaconState.beacon.owner_image_id)}
                    alt="user profile"
                />
            );
        }
        else {
            // default image
            return <AccountCircleTwoToneIcon />;
        }
    };

    const beaconImageElement = () => {
        if (isLoading) {
            return <Skeleton variant="rectangular" width="100%" height="100%" />;
        } else {
            if (beaconState.beacon?.image_id) {
                return (
                    <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CardMedia
                            sx={{ height: "100%", maxWidth: "600px" }}
                            component="img"
                            src={getBeaconImageURLOnServer(beaconState.beacon?.image_id)}
                            alt="beacon image"
                        />
                    </Box>);
            } else {
                return <Box></Box>;
            }
        }
    };

    const ownerNameElement = () => {
        if (isLoading) {
            return <Skeleton width={100} height={24} sx={{ ml: 1 }}></Skeleton>;
        } else {
            return (<Typography
                style={{ whiteSpace: "pre-line" }}
                sx={{
                    ml: 1,
                    wordBreak: "break-all",
                    color: AppColor.headerText,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical'
                }} >{beaconState.beacon?.owner_name}</Typography>);
        }
    };

    const beaconDetailElements = () => {
        if (isLoading) {
            return (
                <Box sx={{ px: 3, pt: 2 }}>
                    <Skeleton variant='text' width={200} height={50} />
                    <Skeleton variant='text' width="100%" height={30} sx={{ mt: 2 }} />
                    <Skeleton variant='text' width={160} height={30} />
                    <Skeleton variant='text' width="100%" height={24} sx={{ mt: 2 }} />
                    <Skeleton variant='text' width="100%" height={24} />
                    <Skeleton variant='text' width={140} height={24} />
                </Box>
            );
        } else {
            return (
                <Box sx={{ marginTop: 2, marginX: 2, alignItems: "flex-start", display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                    <Typography
                        sx={{
                            fontWeight: Fonts.SEMI_BOLD,
                            overflow: 'hidden',
                            fontSize: "24px",
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical'
                        }}>{beaconState.beacon?.display_name ? beaconState.beacon?.display_name : "Beacon name"}</Typography>
                    <Typography
                        style={{ whiteSpace: 'pre-line' }}
                        align="left"
                        sx={{
                            marginTop: 2,
                            color: AppColor.subHeaderText,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical'
                        }} >{beaconState.beacon?.display_description ? beaconState.beacon?.display_description : "Beacon description"}</Typography>
                </Box>
            );
        }
    };

    return (
        <Div100vh>
            <Box
                sx={{
                    width: "100vw",
                    height: "100%",
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    background: AppColor.skidattlWhite
                }}>
                <Box sx={{
                    height: "100%",
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <img src="/skidattl-logo-black.png" height={50} alt="Skidattl Logo" />
                    <Box sx={{
                        height: "35%",
                        width: "100%",
                        maxWidth: "600px",
                        backgroundColor: "gray",
                    }}>
                        {beaconImageElement()}
                    </Box>
                    <Box sx={{
                        width: "100%",
                        maxWidth: "600px",
                        height: "65%",
                        backgroundColor: "white",
                        borderTop: "1px solid silver",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}>
                        <Box>
                            <Box sx={{
                                marginTop: "-12px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingX: 2
                            }}>
                                <BeaconCategory category={beaconState.beacon?.category ?? ""} fontSize='16'></BeaconCategory>
                            </Box>
                            {beaconDetailElements()}
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mb: 2, mr: 2 }}>
                                {userProfileImage()}
                                {ownerNameElement()}
                            </Box>
                        </Box>
                    </Box>
                </Box >
            </Box>
        </Div100vh>
    );
};

export default BeaconPage;
